import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import * as env from "../../../../environments/environment";
import { FunctionsService } from '../utilsFunctions/functions.service';
import { UserService } from '../../../shared/services/user/user-service.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  provider = [];

  constructor(private functions: FunctionsService,
              private users: UserService,
              private http: Http,
              private config: ConfigService){

  }

  clearCache() {
    this.provider = [];
  }

  async loadProviders() {
    if (this.provider.length == 0) {
      let token = await this.functions.getUserToken();
      let base = this.users.getDataBase();
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/provider/.json?auth=${token}`).toPromise();    
      this.provider = this.functions.convertObjToArray(data).filter((item) => item);

      this.provider.sort((a,b) => {
        if (a.companyName < b.companyName) {
          return -1
        } else {
          if (a.companyName > b.companyName) {
            return 1
          } else {
            return 0
          }
        }
      });
    }

    return this.provider;
  }


  async getProvider(id) {
    if (this.provider.length == 0) {
      let token = await this.functions.getUserToken();
      let base = this.users.getDataBase();
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/provider/${id}/.json?auth=${token}`).toPromise();    
      
      if (!JSON.parse(data['_body']) || JSON.parse(data['_body']).deleted) {
        return undefined
      } else {
        return JSON.parse(data['_body']);
      }      
    } else {
      let data = undefined;
      let i = 0;
      while (!data && i < this.provider.length) {
        if (this.provider[i].id == id) {
          data = this.provider[i];
        }
        i++;
      }

      return data;
    }
  }

  async saveProvider(dataProvider) {
      let token = await this.functions.getUserToken();
      let base = this.users.getDataBase();

      if (!dataProvider.id) {
        dataProvider.id = await this.config.getNewLastProviderCode();
      } 
      await this.http.put(`${this.functions.getBaseURL()}/dados/provider/${dataProvider.id}.json?auth=${token}`, dataProvider).toPromise();   
      this.clearCache();
      return dataProvider.id;
  }

  async removeProvider(dataProvider) {
    let token = await this.functions.getUserToken();
    let base = this.users.getDataBase();
    dataProvider['deleted'] = true;
    await this.http.put(`${this.functions.getBaseURL()}/dados/provider/${dataProvider.id}.json?auth=${token}`, dataProvider).toPromise();   
    this.clearCache();
  }
}
