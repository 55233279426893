export const OS_STATUS_DG = [
    { label: 'Fila', value: 0 },
    { label: 'Para Fazer', value: 1 },
    { label: 'Em andamento', value: 2 },
    { label: 'Retrabalho', value: 12 },
    { label: 'Aguardando info.', value: 10 },
    { label: 'Testes em Par', value: 3 },
    { label: 'Ajuste Revisão', value: 11 },
    { label: 'Revisar Código', value: 9 },
    { label: 'Aprovado p/ Teste', value: 8 },
    { label: 'Ambiente de Testes', value: 4 },
    { label: 'Aguardando aprov. Cliente', value: 5 },
    { label: 'Aprovado p/ Produção', value: 6 },
    { label: 'Liberado em Produção', value: 7 },
];

export const OS_STATUS_AGB = [
    { label: 'Em Andamento', value: 9 },
    { label: 'Aguardando Técnico', value: 15 },
    { label: 'Aguardando Atendente', value: 10 },
    { label: 'Aguardando Cliente', value: 12 },
    { label: 'Aguardando Terceiro', value: 11 },
    { label: 'Aguardando Teste', value: 14 },
    { label: 'Pendente Fechamento', value: 13 },
];